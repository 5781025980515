<template>
  <v-container fluid>
    <v-row>
      <div class="d-flex justify-space-between mb-5">
        <form class="conFormSubmit conSearchForm mt-5">
          <v-row>
            <v-col md="4" sm="6" cols="12">
              <label class="eachLabel">Offices</label>
              <v-select
                outlined
                v-model="listData.office"
                :items="officesList"
                item-text="name"
                item-value="id"
                placeholder="Offices"
                hide-details="true"
                :menu-props="{ bottom: true, offsetY: true }"
                attach
                append-icon="mdi-chevron-down"
                multiple
                clearable
              ></v-select>
            </v-col>
            <v-col md="4" sm="6" cols="12">
              <label class="eachLabel">Department</label>
              <v-select
                outlined
                v-model="listData.department_id"
                :items="listDataDepartment"
                item-text="name_en"
                item-value="id"
                placeholder="Department"
                hide-details="true"
                :menu-props="{ bottom: true, offsetY: true }"
                attach
                append-icon="mdi-chevron-down"
                multiple
                clearable
              ></v-select>
            </v-col>
            <v-col md="4" sm="6" cols="12" class="d-flex align-end">
              <div class="confBtns">
                <v-btn
                  class="srearchBnt btnAddPrimaryOutline mr-4"
                  @click="getListData()"
                  :disabled="isLoadingSearch"
                  :loading="isLoadingSearch"
                >
                  SEARCH
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </form>
      </div>
    </v-row>
    <v-row>
      <v-col md="6" cols="12">
        <div class="statusTitle">
          Working
          <span class="workingNum">{{ listData.working_users_count }}</span>
        </div>
        <div class="mainDiv">
          <v-row>
            <v-col
              md="4"
              cols="12"
              v-for="item of listData.working_users"
              :key="item"
            >
              <v-card
                class="conOfCardClient"
                color="#42eba3"
                dark
                max-width="200"
              >
                <v-card-title>
                  <div class="conOfCardTitle">
                    <div class="Name">{{ item.name }}</div>
                    <div class="Mail">{{ item.email }}</div>
                    <div class="Attendance">IN--</div>
                  </div>
                </v-card-title>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-col>
      <v-col md="6" cols="12">
        <div class="statusTitle">
          Break
          <span class="breakNum">{{ listData.breaking_users_count }}</span>
        </div>

        <div class="mainDiv">
          <v-row>
            <v-col
              md="4"
              cols="12"
              v-for="item of listData.breaking_users"
              :key="item"
            >
              <v-card
                class="conOfCardClient"
                color="#efd18a"
                dark
                max-width="200"
              >
                <v-card-title>
                  <div class="conOfCardTitle">
                    <div class="Name">{{ item.name }}</div>
                    <div class="Mail">{{ item.email }}</div>
                    <div class="break">Break--</div>
                  </div>
                </v-card-title>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-col>
      <v-col md="6" cols="12">
        <div class="statusTitle">
          Out <span class="outNum">{{ listData.left_users_count }}</span>
        </div>
        <div class="mainDiv">
          <v-row>
            <v-col
              v-for="item of listData.left_users"
              :key="item"
              md="4"
              cols="12"
            >
              <v-card
                class="conOfCardClient d-flex"
                color="#f58493e6"
                dark
                max-width="200"
              >
                <v-card-title>
                  <div class="conOfCardTitle">
                    <div class="Name">{{ item.name }}</div>
                    <div class="Mail">{{ item.email }}</div>
                    <div class="out">Out--</div>
                  </div>
                </v-card-title>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { ApiService } from "@/modules/shared/services/api";
let apiServices = new ApiService();
export default {
  data: () => ({
    listDataDepartment: {},
    listData: {
      office: "",
      department_id: "",
    },
    officesList: [
      {
        name: "Company",
        id: 2,
      },
      {
        name: "Home",
        id: 1,
      },
    ],
  }),
  computed: {},
  methods: {
    getListData() {
      this.isLoading = true;
      apiServices
        .get(
          `track/users?office=${this.listData.office}&department_id=${this.listData.department_id}`
        )
        .then((res) => {
          if (res) {
            this.listData = res;
            this.isLoading = false;
          } else this.isLoading = false;
        });
    },
    getListDataDepartment() {
      this.isLoading = true;
      apiServices.get(`departments`).then((res) => {
        if (res) {
          this.listDataDepartment = res.data;
          console.log("listDataDepartment", this.listDataDepartment);
          this.isLoading = false;
        } else this.isLoading = false;
      });
    },
  },
  created() {
    this.getListData();
    this.getListDataDepartment();
  },
  watch: {},
};
</script>
<style lang="scss" scoped>
@import "./_working.scss";
</style>
